import { TranslationKey } from '~/i18n/TranslationKeys'
import { filterColors } from '../config/FiltersColors'
import { IChartDataPoint } from './../types/IChartDataPoint'
import { INamedArray } from './../types/INamedArray'
class UtilGraph {
	public readonly defaultGrid = {
		// this is needed in order not to 'cut' the last text value
		top: 5,
		right: 5,
		bottom: 0,
		left: 0,
		containLabel: true,
	}

	public defaultYAxis(dataValues: INamedArray<IChartDataPoint<any, any>>[]): any {
		return dataValues.map((entry, index) => ({
			type: 'value',
		}))
	}

	public defaultToolbox() {
		return {
			feature: {
				dataZoom: {
					yAxisIndex: 'none',
					filterMode: 'none',
				},
				restore: {},
			},
		}
	}

	public defaultXaxis<T, K>(
		dataValues: INamedArray<IChartDataPoint<T, K>>[],
		mapLabel: (entry: K[], index: number) => string,
		mapData: (entry: IChartDataPoint<T, K>[]) => any = (entry: any[]) => entry.map((val) => val.x),
		splitNumber: number = 12
	) {
		return dataValues.map((entry, index) => {
			const interval = this.getInterval(entry.entries, splitNumber)

			return {
				// show only 1 x axis per time
				show: index == 0,
				type: 'category',
				data: mapData(entry.entries),
				splitNumber,
				axisLabel: {
					interval,
					formatter: mapLabel,
				},
				splitLine: {
					show: true,
				},
				axisTick: {
					alignWithLabel: true,
					interval,
				},
			}
		})
	}

	public defaultSeries(
		dataValues: INamedArray<IChartDataPoint<any, any>>[],
		getData: (dataPoints: IChartDataPoint<any, any>[], index: number) => any[],
		type: string = 'line',
		areaStyle: Object | undefined = undefined,
		showSymbol: boolean = false
	) {
		return dataValues.map((entry, index) => ({
			name: useLocale().translate(entry.name as TranslationKey),
			type,
			areaStyle,
			showSymbol,
			data: getData(entry.entries, index),
			xAxisIndex: index,
			itemStyle: {
				color: filterColors[index % filterColors.length].filter,
			},
		}))
	}

	public defaultTooltip(
		formatX: (entry: any, seriesIndex: number) => string,
		formatY: (entry: any, seriesIndex: number) => string
	) {
		return {
			trigger: 'axis',
			valueFormatter: formatY,
			axisPointer: {
				type: 'cross',
				label: {
					// while it doesn't show, the formatting is used for the tooltip
					show: false,
					formatter: (entry: any) => {
						const axis = entry.axisDimension
						const data = entry.value

						switch (axis) {
							case 'x': {
								return formatX(data, entry.axisIndex)
							}
							case 'y': {
								return formatY(data, entry.axisIndex)
							}
							default: {
								return ''
							}
						}
					},
				},
			},
		}
	}

	public getInterval(dataset: IChartDataPoint<any, any>[], splitNumber: number) {
		// TODO: switch on Date, number (max - min)
		return Math.round(dataset.length / splitNumber)
	}

	// TODO: simplify
	public alignData<T, K>(dataToAlign: INamedArray<IChartDataPoint<T, K>>[], defaultValue: T) {
		const isDate = this.isXDate(dataToAlign)
		const allXValues = new Set()

		// reminder: if you change this, keep in mind the complexity:
		// in this case, thanks to the use of set it is only O(N*M),
		// if you cancel the set, it skyrockets to O(N*M)^2
		dataToAlign.forEach((filterData) => {
			filterData.entries.forEach((dataPoint) => {
				if (isDate) {
					allXValues.add((dataPoint.x as Date).valueOf())
				} else {
					allXValues.add(dataPoint.x)
				}
			})
		})

		return dataToAlign.map((arr) => {
			let iterator = 0
			const xArray = Array.from(allXValues)

			if (arr.entries.length == 0) {
				return xArray.map((x) => ({
					x,
					y: defaultValue,
				}))
			}

			let currentDataPoint = arr.entries[0]

			const entries = xArray.map((xVal: any) => {
				let val = defaultValue
				const actualX = isDate ? new Date(xVal) : xVal
				const currentX = isDate ? (currentDataPoint.x as Date).valueOf() : xVal

				if (currentX === actualX) {
					val = currentDataPoint.y
					iterator++
					if (iterator < arr.entries.length) {
						currentDataPoint = arr.entries[iterator]
					}
				}

				return {
					x: isDate ? new Date(actualX as number) : actualX,
					y: val,
				}
			})

			return {
				name: arr.name,
				entries,
			}
		})
	}

	isXDate(data: INamedArray<IChartDataPoint<unknown, unknown>>[]) {
		const element = data.find((el) => el.entries.length > 0)?.entries

		if (element == undefined) {
			return false
		}

		return element[0].x instanceof Date
	}

	public isDataComparable(data: INamedArray<IChartDataPoint<unknown, unknown>>[]) {
		const lengthsCounter = new Set<number>()
		data.forEach((entry) => lengthsCounter.add(entry.entries.length))

		return lengthsCounter.size == 1
	}
}

export const utilGraph = new UtilGraph()
