export interface IFilterColors {
    background: string
    nonInteractiveBackground: string
    filter: string
}

export const filterColors: IFilterColors[] = [
    {
        background: 'bg-light-blue-100 hover:bg-light-blue-200',
        nonInteractiveBackground: 'bg-light-blue-100',
        filter: '#27D0E6',
    },
    {
        background: 'bg-yellow-100 hover:bg-yellow-200',
        nonInteractiveBackground: 'bg-yellow-100',
        filter: '#FBC334',
    },
]
