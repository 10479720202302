<script lang="ts" setup>
import { TranslationKey } from '~/i18n/TranslationKeys';
import { SpSvg } from '~~/src/autogen/SpSvg';
import { EmitsEnum } from '~~/src/constants/emits';
import { IconSizes } from '~~/src/constants/iconSizes';


const props = defineProps({
    color: { type: String, required: true },
    text: { type: String, required: true },
    isEnabled: { type: Boolean, default: true },
    legendOverride: { type: String, default: '' },
    hideFilter: { type: Boolean, default: false }
})
const { color, text, isEnabled, legendOverride, hideFilter } = toRefs(props)

// toggle state
const internalState = ref(isEnabled.value)
watch(isEnabled, () => internalState.value = isEnabled.value)

const getIcon = computed(() => internalState.value ? SpSvg.BasicHide : SpSvg.BasicShow)

const emit = defineEmits([EmitsEnum.Change])
const onClick = () => {
    internalState.value = !internalState.value
    emit(EmitsEnum.Change, internalState.value)
}
const iconStyle = computed(() => {
    if (legendOverride.value == '' || legendOverride.value == undefined) {
        return `background: ${color.value};`
    }
    return ''
})
</script>

<template>
    <div class="flex flex-row gap-2 items-center" :class="internalState ? '' : 'opacity-50'">
        <div class="w-3 h-3 rounded-full" :style="iconStyle" :class="legendOverride" />
        <CommonText class="text-sm" :text="(text as TranslationKey)" />
        <CommonIcon v-if="!hideFilter" class="cursor-pointer" :icon-name="getIcon" :icon-size="IconSizes.XS"
            @click="onClick" />
    </div>
</template>